<section class="main px-3">
  <div class="g-0">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div class="tableheading row">
        <div class="col-md-8 ">
          <div class="row">
            <div class="col-md-3 pt-2">
              <div class="mt-3">
                <input type="text" (keyup)="onUpSearch($event.target.value)" class="search"
                  placeholder="Search here..." />
              </div>
            </div>
            <div class="col-md-3 pt-2">
              <div class="mt-3">
                <app-select-search label="Project" placeholder="Filter by project" option-label="name" option-value="id"
                  [disable]="loaderProject" [filtering]="false" [options]="projects"
                  [formControl]="projectIdFormControl" [options-visible]="loaderProject && currentFilterProject"
                  (change)="onChangeProject($event)">
                </app-select-search>
              </div>
            </div>
            <div class="col-md-3 pt-2">
              <div class="mt-3">
                <app-select-search label="Environment" placeholder="Filter by environment" option-label="name"
                  option-value="id" [disable]="loaderEnvironment || !projectIdFormControl.value" [filtering]="false"
                  [options]="environments" [formControl]="environmentIdFormControl"
                  [options-visible]="loaderEnvironment && currentFilterEnvironment"
                  (change)="onChangeEnvironment($event)">
                </app-select-search>
              </div>
            </div>
          </div>
        </div>
        <div class="box_right col-md-4">
          <div class="row p-0">
            <div class="col-md-12 text-right">
              <div class="mt-3">
                <button class="btn-refresh" title="Reload deployments"
                  (click)="this.getAllDeployments(page, limit, search)">
                  <i class="bi bi-arrow-clockwise"></i>
                </button>
                <a (click)="onCreate()" class="btn-right blue">
                  Create new deployment
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Table -->
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-4">
        <div class="table_wrapper">
          <div style="font-size: 14px;" class="text-right pb-2">
            <span>
              List of deployments ({{ total.toString().padStart(2, "0") }})
            </span>
          </div>
          <div class="content_table">
            <div class="table-responsive">
              <table class="table member-table">
                <thead>
                  <tr>
                    <th style="cursor: pointer;" (click)="OnSortByField('metadata.name', this.sortByField['metadata.name'])">
                      Name
                      <i class="fa fa-long-arrow-up" *ngIf="this.sortByField['metadata.name']"></i>
                      <i class="fa fa-long-arrow-down" *ngIf="!this.sortByField['metadata.name']"></i>
                    </th>
                    <th>Domain</th>
                    <th>Environment</th>
                    <th style="cursor: pointer;" (click)="OnSortByField('lastUpdatedDate', this.sortByField['lastUpdatedDate'])">
                      Updated At
                      <i class="fa fa-long-arrow-up" *ngIf="this.sortByField['lastUpdatedDate']"></i>
                      <i class="fa fa-long-arrow-down" *ngIf="!this.sortByField['lastUpdatedDate']"></i>
                    </th>
                    <th style="cursor: pointer;" (click)="OnSortByField('action', this.sortByField['action'])">
                      Status
                      <i class="fa fa-long-arrow-up" *ngIf="this.sortByField['action']"></i>
                      <i class="fa fa-long-arrow-down" *ngIf="!this.sortByField['action']"></i>
                    </th>
                    <th class="role">Action</th>
                  </tr>
                </thead>

                <tbody *ngIf="total > 0 && deploymentLoaded">
                  <tr
                    *ngFor="let deployment of deployments | paginate : { itemsPerPage: limit, currentPage: page, totalItems: total } ; let i = index">
                    <td>
                      <button title="Go to advanced deploy" class="btn-actions action-workload"
                        (click)="onGotoDeliver(deployment)">
                        {{ elipsize(deployment?.metadata?.name, 20) }}
                      </button>
                    </td>
                    <td>
                      <a [href]="'http://'+deployment?.spec?.ingress?.host" target="_blank" rel="noopener noreferrer">
                        {{ elipsize(deployment?.spec?.ingress?.host, 20) }}
                      </a>
                    </td>
                    <td>
                      {{ deployment?.environmentName }}
                    </td>
                    <td> {{ formatDateToHuman(deployment?.lastUpdatedDate) }} </td>
                    <td style="text-align: center !important;">
                      <button class="btn-msg d-flex justify-content-between" title="Click to show details"
                        (click)="onOpenInfoStatusModal(templateInfoStatus, deployment)"
                        [class.success-color]="!deploymentIsFailed(deployment)"
                        [class.danger-color]="deploymentIsFailed(deployment)">
                        <span>{{ deployment?.action }}</span>
                        <span class="mx-1">{{ deployment?.liveReadyReplicas }}/{{ deployment?.desiredReplicas }}</span>
                        <i class="fa fa-info-circle" *ngIf="!deploymentIsFailed(deployment)"></i>
                        <i class="fa fa-warning" *ngIf="deploymentIsFailed(deployment)"></i>
                      </button>
                    </td>
                    <td class="">

                      <button class="btn-actions" (click)="onEdit(deployment)">
                        <i class="fa fa-edit" title="Edit deployment" style="color: #00a150;"></i>
                      </button>

                      <button class="btn-actions" (click)="onDuplicate(deployment)">
                        <i class="fa fa fa-copy" title="Duplicate deployment" style="color: #4077e4;"></i>
                      </button>

                      <button class="btn-actions" (click)="onOpenDeleteModal(template, deployment)">
                        <i class="fa fa-trash" title="Delete deployment" style="color: #fd8484;"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <spinner *ngIf="!deploymentLoaded"></spinner>
              <p *ngIf="!deploymentLoaded" class="no-app-found">Loading deploymens</p>
              <p *ngIf="deploymentLoaded && total == 0" class="no-app-found">No deployment found.</p>
            </div>
            <div class="pcontrols" *ngIf="deployments.length > 0">
              <pagination-controls previousLabel="" nextLabel="" (pageChange)="getPage($event)"></pagination-controls>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<ng-template #template>
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="delete-popup" [formGroup]="deleteDeployForm">
        <div *ngIf="!cannotDelete">
          <h3 class="heading">Are you sure ?</h3>
          <div class="delete-text">
            Delete deployment <br />« <strong>{{ currentDeployment.metadata?.name }}</strong> »
          </div>
          <input (keyup)="onUpDeploymentName($event.target.value)" type="text" class="form-control"
            placeholder="Type name" formControlName="name" />
        </div>
        <div class="mt-4">
          <input type="checkbox" id="forced" value="1" formControlName="forced">
          <label for="forced" class="ml-2" style="font-weight: normal; font-size: 16px;">Force delete</label>
        </div>
        <span class="alertMessage" *ngIf="deleteDeployForm.get('forced').value">
          ({{"The deployment may not be deleted in the cluster"}})</span>
        <div class="attachment-link mt-3 d-flex justify-content-between">
          <button class="btn-delete" type="button" [disabled]="deleteDeployForm.invalid"
            (click)="onSubmitDeleteDeployment()">
            Delete
          </button>
          <button class="btn-cancel" (click)="onCloseDialog()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #templateInfoStatus>
  <div class="modal-content-info-status">
    <div class="modal-header">
      <button type="button" class="m-0 p-0 popup-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="onCloseDialog()">
        <i class="bi bi-x-lg"></i>
      </button>
    </div>

    <div class="modal-body">
      <div class="heading mb-2">
        Status informations <br>
        <small>{{ currentDeployment.metadata?.name }}</small>
      </div>
      <div class="status-wrapper">
        <div class="row px-2">
          <div class="col-md-12 default-status mb-1"
            *ngFor="let condition of currentDeployment?.status?.conditions; let i=index"
            [class.failed-status]="deploymentTypeFailed.includes(condition.type)"
            [class.success-status]="!deploymentTypeFailed.includes(condition.type)">
            <div class="d-flex justify-content-between mb-2">
              <strong>{{ i+1 }} - {{ condition?.type }} {{ deploymentTypeFailed.includes(condition.type) ? '- Critical'
                :
                '' }}</strong>
              <strong>{{ condition?.reason }}</strong>
            </div>
            <div>
              {{ condition?.message ? condition.message : condition?.type+' with successfull' }}
            </div>
            <div class="text-right">
              <small>{{ formatDateToHuman(condition?.lastTransitionTime) }}</small>
            </div>
          </div>
          <div class="default-status failed-status" *ngIf="deploymentIsFailed(currentDeployment, false)">
            <div class="d-flex justify-content-between mb-2">
              <strong>
                {{ currentDeployment?.status?.conditions.length+1 }} - PodSchedulingError- Critical
              </strong>
              <strong>
                Error
              </strong>
            </div>
            <div>
              Successful deployment! An error has been detected on the pod. Please contact support via
              <a href="mailto:support@gocno.io">
                <strong>support&#64;gocno.io</strong>
              </a>
              to have the error corrected.
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <button class="btn-cancel" (click)="onCloseDialog()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
