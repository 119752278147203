import {Environment} from './environment';
import {Tag} from './tag';
import {ProvisioningRequest} from './provisioning_request';
import {GetValueAndUnit} from '../../utils/unit';
import {unitValues} from '../../utils/const';

export class Project {
  id: string;
  userFullName: string;
  userEmail: string;
  name: string;
  organizationId: string;
  tags: Tag[];
  environments: Environment[];
  projectMembers: ProjectMember[];
  provisioningRequests: ProvisioningRequest[];
  createdDate: Date;
  memoryLimit: string;
  memoryRequest: string;
  cpuLimit: string;
  cpuRequest: string;
  storage: string;
}

export class ProjectMember {
  id: string;
  userFullName: string;
  email: string;
  username: string;
  role: string;
  projectId: string;
  organizationId: string;
}

export const getCpu = (project: Project): {limit: number, request: number, used: number, percentUsed: number} => {
  let limit = 0;
  let request = 0;
  for (const pr of project.provisioningRequests) {
    if (pr.status === 'Accepted') {
      let v = GetValueAndUnit(pr.cpuLimit);
      limit += v.value * unitValues[v.unity === '' ? 'core' : v.unity];
      v = GetValueAndUnit(pr.cpuRequest);
      request += v.value * unitValues[v.unity === '' ? 'core' : v.unity];
    }
  }
  let cpuUsed = 0;
  for (const env of project.environments) {
    const v = GetValueAndUnit(env.cpuLimit);
    cpuUsed += v.value * unitValues[v.unity === '' ? 'core' : v.unity];
  }
  return {
    limit: Number((limit / unitValues.m).toFixed(2)),
    request: Number((request / unitValues.m).toFixed(2)),
    used: Number((cpuUsed / unitValues.m).toFixed(2)),
    percentUsed: Number(((cpuUsed / (limit === 0 ? 1 : limit)) * 100).toFixed(0))
  };
};

export const getMemory = (project: Project): {limit: number, request: number, used: number, percentUsed: number} => {
  let limit = 0;
  let request = 0;
  for (const pr of project.provisioningRequests) {
    if (pr.status === 'Accepted') {
      let v = GetValueAndUnit(pr.memoryLimit);
      limit += v.value * unitValues[v.unity];
      v = GetValueAndUnit(pr.memoryRequest);
      request += v.value * unitValues[v.unity];
    }
  }
  let memoryUsed = 0;
  for (const env of project.environments) {
    const v = GetValueAndUnit(env.memoryLimit);
    memoryUsed += v.value * unitValues[v.unity];
  }
  return {
    limit: Number((limit / unitValues.Gi).toFixed(2)),
    request: Number((request / unitValues.Gi).toFixed(2)),
    used: Number((memoryUsed / unitValues.Gi).toFixed(2)),
    percentUsed: Number(((memoryUsed / (limit === 0 ? 1 : limit)) * 100).toFixed(0))
  };
};

export const getStorage = (project: Project): {limit: number, used: number, percentUsed: number} => {
  let storage = 0;
  for (const pr of project.provisioningRequests) {
    if (pr.status === 'Accepted') {
      const v = GetValueAndUnit(pr.storage);
      storage += v.value * unitValues[v.unity];
    }
  }
  let used = 0;
  for (const env of project.environments) {
    const v = GetValueAndUnit(env.storage);
    used += v.value * unitValues[v.unity];
  }
  return {
    limit: Number((storage / unitValues.Gi).toFixed(2)),
    used: Number((used / unitValues.Gi).toFixed(2)),
    percentUsed: Number(((used / (storage === 0 ? 1 : storage)) * 100).toFixed(0))
  };
};



