import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { LayoutComponent } from './layout/layout.component';
import { AddProviderComponent } from './modules/clusters/pages/add-provider/add-provider.component';
import { CheckConfigComponent } from './modules/dashboard/pages/check-config/check-config.component';
import { MainComponent } from "./layouts/main/main.component";
import { AddProviderComponent as AddProviderDashComponent } from './modules/dashboard/pages/add-provider/add-provider.component';
import { ListOrganisationComponent } from './modules/organization/page/list-organisation/list-organisation.component';
import { AppDashboardComponent } from './modules/app/app/app.component';
import { AppDetailsComponent } from './modules/app/app-details/app-details.component';
import { AppLogComponent } from './modules/app/app-log/app-log.component';
import { RegistryComponent } from './modules/app/registry/registry.component';
import { DomainComponent } from './modules/app/domain/domain.component';
import { CheckSubscriptionGuard } from './shared/guards/check-subscription';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { ApplicationComponent } from './modules/deploy/pages/delivery/application/application.component';
import { DetailApplicationComponent } from './modules/deploy/pages/delivery/application/detail-application/detail-application.component';
import { DeploymentComponent } from './modules/deploy/pages/delivery/deployment/deployment.component';
import { FormDeploymentComponent } from './modules/deploy/pages/delivery/deployment/form-deployment/form-deployment.component';
import { DetailDeploymentComponent } from './modules/deploy/pages/delivery/deployment/detail-deployment/detail-deployment.component';

const routes: Routes = [
  { path: 'check-config/:orgId', component: CheckConfigComponent },
  {
    path: 'add-provider',
    component: AddProviderComponent,
    canActivate: [CheckSubscriptionGuard],
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: MainComponent,
    children: [
      // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'unauthorized/:type',
        component: UnauthorizedComponent,
        canActivate: [CheckSubscriptionGuard],
      },
      {
        path: 'dashboard/add-provider',
        component: AddProviderDashComponent,
        canActivate: [CheckSubscriptionGuard],
      },
      {
        path: 'app-details',
        component: AppDetailsComponent,
        canActivate: [CheckSubscriptionGuard],
      },
      {
        path: 'app-log',
        component: AppLogComponent,
        canActivate: [CheckSubscriptionGuard],
      },

      {
        path: 'apps',
        component: AppDashboardComponent,
        canActivate: [CheckSubscriptionGuard],
      },
      {
        path: 'registry',
        component: RegistryComponent,
        canActivate: [CheckSubscriptionGuard],
      },
      {
        path: 'domain',
        component: DomainComponent,
        canActivate: [CheckSubscriptionGuard],
      },

      {
        path: 'deliver',
        canActivate: [CheckSubscriptionGuard],
        children: [
          {
            path: '',
            component: ApplicationComponent,
            data: { name: 'list-applications' },
          },
          {
            path: ':id',
            component: DetailApplicationComponent,
          },
        ],
      },

      {
        path: 'deploy',
        canActivate: [CheckSubscriptionGuard],
        children: [
          {
            path: '',
            component: DeploymentComponent,
            data: { name: 'list-deployments' },
          },
          {
            path: 'create',
            component: FormDeploymentComponent,
          },
          {
            path: 'edit/:id',
            component: FormDeploymentComponent,
          },
          {
            path: 'detail/:id',
            component: DetailDeploymentComponent,
          },
        ],
      },

      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'clusters',
        canActivate: [CheckSubscriptionGuard],
        loadChildren: () =>
          import('./modules/clusters/clusters.module').then(
            (m) => m.ClustersModule
          ),
      },
      {
        path: 'onboard',
        canActivate: [CheckSubscriptionGuard],
        loadChildren: () =>
          import('./modules/onboard/onboard.module').then(
            (m) => m.OnboardModule
          ),
        data: { activeId: '1' },
      },
      {
        path: 'delivery',
        canActivate: [CheckSubscriptionGuard],
        loadChildren: () =>
          import('./modules/deploy/deploy.module').then((m) => m.DeployModule),
        data: { activeId: '2' },
      },
      {
        path: 'members',
        canActivate: [CheckSubscriptionGuard],
        loadChildren: () =>
          import('./modules/secure/secure.module').then((m) => m.SecureModule),
        data: { activeId: '3' },
      },
      {
        path: 'parameters',
        canActivate: [CheckSubscriptionGuard],
        loadChildren: () =>
          import('./modules/parameters/parameters.module').then(
            (m) => m.ParametersModule
          ),
        data: { activeId: '4' },
      },
      {
        path: 'migration',
        canActivate: [CheckSubscriptionGuard],
        loadChildren: () =>
          import('./modules/migration/migration.module').then(
            (m) => m.MigrationModule
          ),
      },
      // { path: '**',   redirectTo: '/dashboard', pathMatch: 'full' },

      {
        path: 'organizations',
        canActivate: [CheckSubscriptionGuard],
        children: [
          {
            path: 'list',
            component: ListOrganisationComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
