import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Deployment } from 'src/app/data/model/deployment';
import { GetRecordsModel } from 'src/app/data/model/GetRecordsModel';
import { ShareService } from 'src/app/data/service/share.service';

@Injectable({
  providedIn: 'root',
})
export class DeploymentService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };

  baseURL: string;
  constructor(
    private httpClient: HttpClient,
    private shareService: ShareService
  ) {
    this.baseURL = this.shareService.apiBaseURL + '/api/v1/organizations/';
  }

  getAll(page?: any,limit?: any,search?: any, projectId?: any, environmentId?: any): Observable<GetRecordsModel<Deployment>> {
    const orgId = this.shareService.organizationSelected?.id;
    let url = `${this.baseURL}${orgId}/apps?page=${page}&limit=${limit}`;

    if (search) {
      url = `${url}&search=${search}`;
    }
    if (projectId) {
      url = `${url}&project-id=${projectId}`;
    }
    if (environmentId) {
      url = `${url}&environment-id=${environmentId}`;
    }
    return this.httpClient.get<GetRecordsModel<Deployment>>(url);
  }

  getDeployment(projectId: string, environmentId: string, id: string): Observable<Deployment> {
    const orgId = this.shareService.organizationSelected?.id;
    const url = `${this.baseURL}${orgId}/projects/${projectId}/environments/${environmentId}/apps/${id}`;
    return this.httpClient.get<Deployment>(url);
  }

   deleteDeployment(projectId: string, environmentId: string, id: string, force = false): Observable<void> {
    const orgId = this.shareService.organizationSelected?.id;
    const url = `${this.baseURL}${orgId}/projects/${projectId}/environments/${environmentId}/apps/${id}`+`?force=`+`${force}`;
    return this.httpClient.delete<void>(url);
  }

  createDeployment(projectId: string, environmentId: string, data: any): Observable<Deployment> {
    const orgId = this.shareService.organizationSelected?.id;
    const url = `${this.baseURL}${orgId}/projects/${projectId}/environments/${environmentId}/apps`;
    return this.httpClient.post<Deployment>(url, data);
  }

  updateDeployment(projectId: string, environmentId: string, id: string, data:any): Observable<Deployment> {
    const orgId = this.shareService.organizationSelected?.id;
    const url = `${this.baseURL}${orgId}/projects/${projectId}/environments/${environmentId}/apps/${id}`;
    return this.httpClient.put<Deployment>(url, data);
  }
}
