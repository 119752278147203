import {EnvironmentUser} from './environment-user';

export class Environment {
  id: string;
  name: string;
  status: string;
  uidUser: string;
  uidCluster: string;
  agentId: string;
  cpuRequest: string;
  memoryRequest: string;
  storage: string;
  cpuLimit: string;
  memoryLimit: string;
  cpuUsedPercentage: number;
  memoryUsedPercentage: number;
  storageUsedPercentage: number;
  environmentUsers: EnvironmentUser[];
  createdDate: Date;
  clusterId: string;
  isProd: boolean;
  projectId: string;
  organizationId: string;
  error: string;
  unLimitedQuota: Boolean;
  memoryUsed: any;
  projectName: string;
  clusterType: any;
  namespace: string;
}
